
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'AsideMenu',

	setup() {
		const itemsMenu = [
			{
				text: 'Обзор показателей',
				url: '/'
			},
			{
				text: 'Карта склада',
				url: '/warehouse-map'
			},
			{
				text: 'Приемка',
				url: '/lots',
				children: [
					{
						text: 'Все партии',
						url: '/lots'
					},
					{
						text: 'Добавить партию',
						url: '/lot/add'
					}
				]
			},
			{
				text: 'Добавление',
				url: '/products',
				children: [
					{
						text: 'Все товары',
						url: '/products'
					},
					{
						text: 'Скидки',
						url: '/products-to-sale'
					},
					{
						text: 'Добавить товар',
						url: '/product/add'
					},
					{
						text: 'Найти по штрихкоду',
						url: '/search/variation'
					},
					{
						text: 'Переместить на склад',
						url: '/move/product/transfer-storage'
					}
				]
			},
			{
				text: 'Заказы',
				url: '/orders',
				children: [
					{
						text: 'Все заказы',
						url: '/orders'
					},
					{
						text: 'Сборка',
						url: '/orders/collecting'
					},
					{
						text: 'Отправка',
						url: '/orders/sending'
					},
					{
						text: 'Отправленные',
						url: '/orders/shipped'
					},
					{
						text: 'Полученные',
						url: '/orders/received'
					},
					{
						text: 'Найти по номеру',
						url: '/search/order'
					},
					{
						text: 'Переместить коробки',
						url: '/move/order/boxes'
					}
				]
			},
			{
				text: 'Сервис',
				url: '/size/add',
				children: [
					{
						text: 'Добавление размеров',
						url: '/size/add'
					},
					{
						text: 'Фотомодели',
						url: '/photomodels'
					},
					{
						text: 'Перемещение товаров KingMode',
						url: '/move/product/kingmode'
					},
					{
						text: 'Управление размерами',
						url: '/size/manager'
					},
					{
						text: 'Управление курсом евро',
						url: '/currencies'
					},
					{
						text: 'Управление главным экраном',
						url: '/home/edit'
					},
					{
						text: 'Отчеты о продажах',
						url: '/reports/'
					}
				]
			}
		]

		return { itemsMenu }
	}
})
